import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { listServices } from "../../../_redux/actions/services";
import { CLEAR } from "../../../_redux/actions/types";
import { setFavorites } from "../../../_redux/actions/user";
import Categories from "./Categories";
import Notifications from "./Notifications";
import Search from "./Search";
import { getWallet } from "../../../_redux/actions/auth";
import { agentCategories } from "../../../_constant/dashboardCategories";
import adminDashboard, {
  getAdminServices,
} from "../../../_constant/adminDashbaord";
import { _hasRight } from "../../../_constant/admin_rights";
import { _isAgent } from "../../../_constant/user_rights";
import { clearSearch } from "../../../_redux/storeSlice";
import { scroll } from "../../_common/common";

const getOriginalItems = () => JSON.parse(localStorage.getItem("services"));

export const getServices = (setServices, setFavorites, services, profile) => {
  const settings = profile.settings;
  let items = "";
  items = services.length ? services : getOriginalItems();
  if (items) {
    const vendors = getVendors();
    const filtered = filterServices(items.concat(vendors), settings.favorites);
    const favs = settings.favorites?.map(
      (i) =>
        getServicesAndGroups() &&
        getServicesAndGroups()?.filter((j) => j.code == i)[0]
    );

    setServices(
      filtered.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    );
    setFavorites(favs);
  }
};

const getVendors = () =>
  getOriginalItems() &&
  getOriginalItems().reduce((res, i) => {
    if (
      i &&
      i.group_by_vendor &&
      res?.filter((j) => j.code == i.vendor.code).length == 0
    )
      res.push({
        name: i.vendor.display_name,
        code: i.vendor?.code,
        logo_name: i.vendor.logo_name,
        filter_by_vendor: true,
      });
    return res;
  }, []);

const getServicesAndGroups = () =>
  getOriginalItems() && getOriginalItems().concat(getVendors());

const filterServices = (items, favs) => {
  return items?.filter(
    (e) => e && !favs.includes(e.code) && !e.group_by_vendor
  );
};

const clear = (dispatch) => {
  useEffect(() => {
    dispatch({ type: CLEAR });
    dispatch(clearSearch());
  });
};
const wallet = (dispatch) => {
  useEffect(() => {
    dispatch(getWallet());
  }, []);
};
const Dashboard = () => {
  const serviceReducer = useSelector(({ ServicesReducer }) => ServicesReducer);
  const profile = useSelector(({ walletReducer }) => walletReducer).profile;
  const [services, setServices] = useState(serviceReducer.list);
  const [favorites, setFavorites] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const dispatch = useDispatch();
  wallet(dispatch);
  useEffect(() => {
    if (!localStorage.getItem("services")) dispatch(listServices());
    getServices(
      setServices,
      setFavorites,
      serviceReducer.list,
      profile,
      favorites
    );
  }, [serviceReducer.list]);
  clear(dispatch);

  return (
    <div className="dashboard">
      <Categories
        setIsSearching={setIsSearching}
        setServices={setServices}
        profile={profile}
        setFavorites={setFavorites}
        categories={agentCategories}
      />
      <div>
        <div className="mb-3">
          <Search
            setIsSearching={setIsSearching}
            setServices={setServices}
            profile={profile}
            setFavorites={setFavorites}
          />
        </div>
        <div className="services-container">
          {!isSearching && (
            <Favorites
              favorites={favorites}
              setFavorites={setFavorites}
              services={services}
              setServices={setServices}
            />
          )}
          <Services
            services={services}
            setServices={setServices}
            setFavorites={setFavorites}
          />
        </div>
      </div>
      <Notifications isAgent={true} />
    </div>
  );
};
const Favorites = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return props.favorites?.map((item, i) => {
    return (
      <div
        className={`dashboard-service ${
          item?.is_online || item?.filter_by_vendor
            ? ""
            : "dashboard-service-offline"
        }`}
        key={i}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragEnter={(e) => {
          e.target.classList.add("dashboard-favorite-dragover");
        }}
        onDragLeave={(e) => {
          e.target.classList.remove("dashboard-favorite-dragover");
        }}
        onDrop={(e) => {
          e.target.classList.remove("dashboard-favorite-dragover");
          const code = e.dataTransfer.getData("text/plain");
          const ar = [...props.services];
          const dragedItem = getServicesAndGroups().find(
            (item) => item && item?.code == code
          );
          const favs = [...props.favorites];
          const index = ar.findIndex((i) => i?.code == code);
          if (item && !item.group_by_vendor)
            index != -1 ? (ar[index] = item) : ar.push(item);
          else  ar.splice(index, 1);

          const favIndex = favs.findIndex((i) => item && i?.code == item?.code);
          if (favIndex != -1) favs[favIndex] = dragedItem;
          else if(!item && favIndex ===-1) 
            favs[favs.findIndex(item=>!item)]=dragedItem
          
          props.setServices(ar);
          props.setFavorites(favs);
          postFavorates(dispatch, favs, ar);
        }}
        onClick={() => clickHandler(item, navigate, props)}
      >
        <Logo item={item} favs={true} />
      </div>
    );
  });
};
const Services = (props) => {
  const navigate = useNavigate();
  return props.services?.map((item, i) => {
    return (
      item && (
        <div
          className={`dashboard-service ${
            item?.is_online || item?.filter_by_vendor
              ? ""
              : "dashboard-service-offline"
          }`}
          key={i}
          draggable={true}
          onDragStart={(e) => {
            e.dataTransfer.setData("text/plain", item.code);
            e.target.classList.add("dashboard-service-dragged");
          }}
          onDragEnd={(e) => {
            e.target.classList.remove("dashboard-service-dragged");
          }}
          onClick={() => clickHandler(item, navigate, props)}
        >
          <Logo item={item} />
        </div>
      )
    );
  });
};
const Logo = ({ item, favs }) => {
  return item ? (
    <span
      onDragEnter={(e) => {
        e.preventDefault();
      }}
    >
      {favs && <FontAwesomeIcon icon={faHeart} color="#ccc" />}
      <img
        src={`/images/${item.logo_name}`}
        className="dashboard-logo"
        draggable="false"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = "/images/iteme.png";
        }}
      />
      <p> {item.name}</p>
    </span>
  ) : (
    ""
  );
};

export const searchClickHandler = (props, key, setTags) => {
  if (!key.length) {
    props.setIsSearching(false);
    return !props.isAdmin
      ? getServices(props.setServices, props.setFavorites, [], props.profile)
      : props.setServices(adminDashboard(props.profile));
  }
  let ar = !props.isAdmin
    ? JSON.parse(localStorage.getItem("services"))
    : getAdminServices.filter(
        (i) =>
          _hasRight(props.profile?.admin_rights, i.rights) ||
          _isAgent(props.profile?.user_rights, i.rights)
      );
  ar = ar?.filter((item) => item.tags?.includes(key));

  props.setServices(ar);
  props.setIsSearching(true);
  if (setTags) {
    document.getElementById("tags").style.display = "none";
    setTags([]);
  }
};
const navigateHandler = (navigate, item) => {
  if (!item?.is_online) return;
  // if (item.code === "RRA") return navigate("/rra");
  if (
    (item.tags.includes("payment") ||
      item.tags.includes("donation") ||
      item.tags.includes("prepaid")) &&
    item.vendor?.code == "ERW"
  )
    navigate(`/validate/${item.code}`);
  else
    navigate(`/${item.code.toLowerCase().replace(/[\s_]/g, "")}/${item.code}`);
  scroll();
};

const clickHandler = (item, navigate, props) => {
  if (item.filter_by_vendor) {
    props.setServices(
      JSON.parse(localStorage.getItem("services"))?.filter(
        (i) => i.vendor?.code == item.code && i.group_by_vendor
      )
    );
    props.setFavorites([]);
    scroll();
  } else item?.is_online && navigateHandler(navigate, item);
};
const postFavorates = (dispatch, favs) => {
  const ar = [];
  favs?.map((i) => ar.push(i && i.code));
  dispatch(setFavorites(ar));
};
export default Dashboard;
