export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const ISLOADING = "ISLOADING";
export const AGENTAPPLICATION = "AGENTAPPLICATION";
export const FAIL = "FAIL";
export const GETBILLERDETAILSUCCESS = "GETBILLERDETAILSUCCESS";
export const GETVALIDATEBILLERPAYEMENTSUCCESS =
  "GETVALIDATEBILLERPAYEMENTSUCCESS";
export const POSTSUCCESS = "POSTSUCCESS";
export const GETCUSTOMERACCOUNT = "GETCUSTOMERACCOUNT";
export const GETVALIDATECASHTOKEN = "GETVALIDATECASHTOKEN";
export const POSTCASHDEPOSIT = "POSTCASHDEPOSIT";
export const POSTREDEEMTOKEN = "POSTREDEEMTOKEN";
export const VIEWCOMMISSIONFEE = "VIEWCOMMISSIONFEE";
export const PAYEMENTFEE = "PAYEMENTFEE";
export const TOKENEXPIRED = "TOKENEXPIRED";
export const GETBILLERFAIL = "GETBILLERFAIL";
export const PASSBOOK = "PASSBOOK";
export const COMMISSION = "COMMISSION";
export const CREDITREQUEST = "CREDITREQUEST";
export const CLEAR = "CLEAR";
export const RECEIPT = "RECEIPT";
export const WALLET = "WALLET";
export const REMITTANCEVALIDATEACCOUNT = "REMITTANCEVALIDATEACCOUNT";
export const REMITTANCEGETRATE = "REMITTANCEGETRATE";
export const REMITTANCESENDMONEY = "REMITTANCESENDMONEY";
export const REMITTANCERECEIVEMONEY = "REMITTANCERECEIVEMONEY";
export const FLOWSTATUS = "FLOWSTATUS";
export const FLOWRESIGN = "FLOWRESIGN";
export const ISCUSTOMER = "ISCUSTOMER";
export const FLOWAPPLY = "FLOWAPPLY";
export const BANKS = "BANKS";
export const COMMISSIONSUM = "COMMISSIONSUM";
export const PASSWORDFAIL = "PASSWORDFAIL";
export const RESETPASSWORDOTPSUCCESS = "RESETPASSWORDOTPSUCCESS";
export const OTPVALIDATESUCESS = "OTPVALIDATESUCESS";
export const PASSWORDCHANGEDSUCCESS = "PASSWORDCHANGEDSUCCESS";
export const HIDEMODAL = "HIDEMODAL";
export const RETYPEPASSWORDFAILED = "RETYPEPASSWORDFAILED";
export const GAMESLIST = "GAMESLIST";
export const GAMELISTFAIL = "GAMELISTFAIL";
export const GETPLAYEDGAMESUCCESS = "GETPLAYEDGAMESUCCESS";
export const CANCELSUCCESS = "CANCELSUCCESS";
export const CHECKCLAIMSUCCESS = "CHECKCLAIMSUCCESS";
export const GETRESULTSUCCESS = "GETRESULTSUCCESS";
export const PLAYGAMESUCCESS = "PLAYGAMESUCCESS";
export const PROFILE = "PROFILE";
export const TERMS = "TERMS";
export const USERS = "USERS";
export const GETAGENTSUCCESS = "GETAGENTSUCCESS";
export const GETPRESIGNEDPUTURL = "GETPRESIGNEDPUTURL";
export const REMOVEFILE = "REMOVEFILE";
export const GETKYCSUCCESS = "GETKYCSUCCESS";
export const CREDITREQUESTSUCCESS = "CREDITREQUESTSUCCESS";
export const USERDETAILS = "USERDETAILS";
export const CLEARUSERDETAILS = "CLEARUSERDETAILS";
export const HISTORYSUCCESS = "HISTORYSUCCESS";
export const KYCCOUNT = "KYCCOUNT";
export const CREDITREQUESTCOUNT = "CREDITREQUESTCOUNT";
export const ISMOBILE = "ISMOBILE";
export const GETADMINUSER = "GETADMINUSER";
export const USERWALLETSUCCESS = "USERWALLETSUCCESS";
export const GETTSM = "GETTSM";
export const TRANSACTIONS = "TRANSACTIONS";
export const USERTRANSACTIONS = "USERTRANSACTIONS";
export const USERCOMISSIONS = "USERCOMISSIONS";
export const MARKASSUCCESS = "MARKASSUCCESS";
export const GETTRANSACTIONENQUIRY = "GETTRANSACTIONENQUIRY";
export const ADVANCEPRODUCTS = "ADVANCEPRODUCTS";
export const REQUESTADVANCESUCCESS = "REQUESTADVANCESUCCESS";
export const ADVANCEHISTORY = "ADVANCEHISTORY";
export const CLEARPAGE = "CLEARPAGE";
export const ECOBANK_FINACIALSTATUS = "ECOBANK_FINACIALSTATUS";
export const URUBUTO_FINACIALSTATUS = "URUBUTO_FINACIALSTATUS";
export const PINDO_FINACIALSTATUS = "PINDO_FINACIALSTATUS";
export const STARTIMES_FINACIALSTATUS = "STARTIMES_FINACIALSTATUS";
export const FLOW_FINACIALSTATUS = "FLOW_FINACIALSTATUS";
export const ITEME_FINACIALSTATUS = "ITEME_FINACIALSTATUS";
export const CANAL_FINACIALSTATUS = "CANAL_FINACIALSTATUS";
export const DUPLICATEWARNING = "DUPLICATEWARNING";
export const RRAINFO = "RRAINFO";
export const REFINVALID = "REFINVALID";
export const TIMEWARNING = "TIMEWARNING";
export const GETAGENTACTIVITY = "GETAGENTACTIVITY";
export const AGENTNOTFOUND = "AGENTNOTFOUND";
export const AGENTFOUND = "AGENTFOUND";
export const LISTSUBAGENT = "LISTSUBAGENT";
export const GETAIRTIMEBILLERS = "GETAIRTIMEBILLERS";
export const MOMOFEE = "MOMOFEE";
export const LISTBASKETS = "LISTBASKETS";
export const GETBASKET = "GETBASKET";
export const ADDTOBASKET = "ADDTOBASKET";
export const SUBMITBASKET = "SUBMITBASKET";
export const STOREBASKETDATA = "STOREBASKETDATA";
export const REMOVEFROMBASKET = "REMOVEFROMBASKET";
export const LISTAGENTTOAGENT = "LISTAGENTTOAGENT";
export const BULKSMSFEE = "BULKSMSFEE";
export const NOTIFICATION = "NOTIFICATION";
export const REQUESTPIN = "REQUESTPIN";
export const SETPIN = "SETPIN";
export const CLEARPIN = "CLEARPIN";
export const SERVICES = "SERVICES";
export const ENDBASKET = "ENDBASKET";
export const PASSBOOKSTATISTICS = "PASSBOOKSTATISTICS";
export const VALIDATEPAY = "VALIDATEPAY";
export const INVOICES = "INVOICES";
export const FLOWCUSTOMERS = "FLOWCUSTOMERS";
export const FLOWLOANS = "FLOWLOANS";
export const TARIFF = "TARIFF";
export const MTNPRODUCTS = "MTNPRODUCTS";
export const VALIDATEOTP = "VALIDATEOTP";
export const OTPSENT = "OTPSENT";
export const FAVORITES = "FAVORITES";
export const LISTNOTIFICATION = "LISTNOTIFICATION";
export const URUBUTOVALIDATE = "URUBUTOVALIDATE";
export const URUBUTOFEE = "URUBUTOFEE";
export const DECODERS = "DECODERS";
export const VALIDATECANAL = "VALIDATECANAL";
export const CANALPRODUCTS = "CANALPRODUCTS";
export const CANALOPTIONS = "CANALOPTIONS";
export const SETDATA = "SETDATA";
export const CLEARRRAINFO = "CLEARRRAINFO";
export const STARTIMESVALIDATE = "STARTIMESVALIDATEI";
export const STARTIMESSUBSCRIBERINFO = "STARTIMESSUBSCRIBERINFO";
export const STARTIMESOPTIONS = "STARTIMESOPTIONS";
export const TRANSACTIONDETAILS = "TRANSACTIONDETAILS";
export const BILLERVALID = "BILLERVALID";
export const RESETPAGE = "RESETPAGE";
export const TRANSACTIONCLAIM = "TRANSACTIONCLAIM";
export const PASSBOOKCLAIM = "PASSBOOKCLAIM";
export const CLEARDETAILS = "CLEARDETAILS";
export const AGENTCLAIMS = "AGENTCLAIMS";
export const CLAIMDETAILS = "CLAIMDETAILS";
export const PASSBOOKDETAILS = "PASSBOOKDETAILS";
export const BRANDINGPRODUCTS = "BRANDINGPRODUCTS";
export const BRANDINGORDERS = "BRANDINGORDERS";
export const BRANDINGPRODUCTSCATEGORIES = "BRANDINGPRODUCTSCATEGORIES";
export const VENDORS = "VENDORS";
export const PROVIDERS = "PROVIDERS";
export const VALIDATERRA = "VALIDATERRA";
export const RRAFEE = "RRAFEE";
export const REQUESTAPPROVEWARNING = "REQUESTAPPROVEWARNING";
export const SETPROVINCE = "SETPROVINCE";
export const SETDISTRICT = "SETDISTRICT";
export const SETSECTOR = "SETSECTOR";
export const SETVILLAGE = "SETVILLAGE";
export const SETCELL = "SETCELL";
export const RRAPROVIDERS = "RRAPROVIDERS";
export const TRAININGVIDEOS = "TRAININGVIDEOS";
export const KTRNBUNDLES = "KTRNBUNDLES";
export const VALIDATEKTRN = "VALIDATEKTRN";
export const BALANCE = "BALANCE";
export const VALIDATECUSTOMER = "VALIDATECUSTOMER";
export const EXPORTDATA = "EXPORTDATA";
export const SIMILARREQUEST = "SIMILARREQUEST";
export const VALIDATECARD = "VALIDATECARD";
export const MTNPROVIDERS = "MTNPROVIDERS";
export const MTNVALIDATE = "MTNVALIDATE";
export const MTNFEES = "MTNFEES";
export const AIRTELPROVIDERS = "AIRTELPROVIDERS";
export const AIRTELVALIDATE = "AIRTELVALIDATE";
export const AIRTELFEES = "AIRTELFEES";
export const REGPROVIDERS = "REGPROVIDERS";
export const REGVALIDATE = "REGVALIDATE";
export const REGFEES = "REGFEES";
export const FDI_FINACIALSTATUS = "FDI_FINACIALSTATUS";
export const ACGROUP_FINACIALSTATUS = "ACGROUP_FINACIALSTATUS";
export const TAPANDGOCARDS = "TAPANDGOCARDS";
export const DSTVVALIDATE = "DSTVVALIDATE"
export const DSTVPACKAGES = "DSTVPACKAGES"
export const DSTVFEES = "DSTVFEES"
export const SWLPOLICIES = "SWLPOLICIES"
export const SWLFEES = "SWLFEES"
export const IREMBOPROVIDERS="IREMBOPROVIDERS" 
export const VALIDATEIREMBO="VALIDATEIREMBO"