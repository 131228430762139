import { CLEAR, IREMBOPROVIDERS, VALIDATEIREMBO } from "../actions/types";

const initialState = {
  validateInfo: null,
  feeInfo: null,
  providers: [],
};

const iremboReducer = (state = initialState, action) => {
  switch (action.type) {
    case VALIDATEIREMBO:
      return {
        ...state,
        validateInfo: action.payload,
      };
   
    case IREMBOPROVIDERS:
      return { ...state, providers: action.payload };
    case CLEAR:
      return { ...state, validateInfo: null};
    default:
      return state;
  }
};

export default iremboReducer;
